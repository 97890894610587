import logo from './logo.png';
import errorIcon from './error.png';
import './App.css';
import React from 'react';

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

function getJSON(url, cb) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);

    request.addEventListener("load", () => {
        if (request.status >= 200 && request.status < 400) {
            // Success!
            var data = JSON.parse(request.responseText);
            cb(data);
        } else {
            // We reached our target server, but it returned an error
            //handle error...
            cb(null);
        }
    });

    //add a listener for the "error" event, which
    //will happen if there was a network error
    request.addEventListener("error", function() {
        cb(null)
    })

    //finally, send the request to the server
    request.send();
}

function buildBrowseLink(path) {
    return "/#path=" + encodeURIComponent(path.join("/"));
}

function buildGalleryLink(idx, path) {
    return "/gallery/" + path.map(encodeURIComponent).join("/") + "?idx=" + idx;
}

function decodeSearch(hash) {
    const out = {};

    hash = hash.substr(1); // Strip # / ?
    const paramPairs = hash.split('&');
    paramPairs.forEach(param => {
        const keyValuePair = param.split('=', 2)
        const key = keyValuePair[0];
        const value = decodeURIComponent(keyValuePair[1]);
        out[key] = value;
    });

    return out;
}

function parseURL(loc) {
    const GALLERY_PREFIX = "/gallery/";
    if (loc.pathname.startsWith(GALLERY_PREFIX)) {
        const path = loc.pathname.substr(GALLERY_PREFIX.length)
                .split("/")
                .map(decodeURIComponent);
        const search = decodeSearch(loc.search);
        let idx = search.idx ? Number(search.idx) : 0;
        if (Number.isNaN(idx)) {
            idx = 0;
        }

        return { mode: 'gallery', path: path, index: idx };
    } else {
        const v = decodeSearch(loc.hash);
        return { mode: 'browse', path: v.path ? v.path.split('/') : [] };
    }
}

class CopyrightBanner extends React.PureComponent {
    static KEY = "agree-with-copyright";
    static VALUE = "agreed-v001";

    constructor(props) {
        super(props);

        this.state = {
            shouldShow: !this.hasAgreed(),
        };
    }

    hasAgreed() {
        return window.localStorage
            && window.localStorage.getItem(CopyrightBanner.KEY) === CopyrightBanner.VALUE;
    }

    onClick = () => {
        if (window.localStorage) {
            window.localStorage.setItem(CopyrightBanner.KEY, CopyrightBanner.VALUE)
        }

        this.setState({ shouldShow: false });
    };

    render() {
        if (!this.state.shouldShow) {
            return null;
        }

        return <header className="kfr-copy-banner">
            <img src={logo} alt="logo" />
            <h1>Keyframer</h1>
            <p>
                This site is a continuation of the now defunct <strong>avatarspirit.net</strong> screenshot archive.
                This is why some screenshots may be labeled with their watermark.
            </p>

            <p>
                Keyframer is a fan service, created and maintained by fans and for fans of the Nickelodeon shows "<em>Avatar: The Last Airbender</em>" and "<em>The Legend of Korra</em>".
                These shows, and all references to it are owned by Nickelodeon, a subsidiary of Viacom.
                This site is in no way affiliated with Nickelodeon or Viacom, recognises their rights and does not profit from this site.
            </p>

            <p>
                Please consider the legal implications before using this site and the material presented on it.
            </p>

            <p className="kfr-copy-confirm">
                <button onClick={this.onClick}>
                    Proceed
                </button>
            </p>
        </header>;
    }
}

class BreadCrumbLink extends React.PureComponent {
    findNewPath() {
        if (this.props.i === -1) {
            return [];
        } else {
            return this.props.path.slice(0, this.props.i + 1);
        }
    }

    onClick = (ev) => {
        ev.preventDefault();

        this.props.onNavigate(this.findNewPath());
    }

    render() {
        const newPath = this.findNewPath();

        return <a
                href={buildBrowseLink(newPath)}
                onClick={this.onClick}>
            { this.props.v ? this.props.v : "/" }
        </a>;
    }
}

class DirectorySelector extends React.PureComponent {
    state = {
        focus: 0,
    };

    navigateToParent() {
        const newPath = this.props.path.slice();
        newPath.pop();
        this.props.onNavigate(newPath);
    }

    navigateToChild(key : String) {
        const newPath = this.props.path.slice();
        newPath.push(key);
        this.props.onNavigate(newPath);
    }

    onRef = (el) => {
        if (el) {
            el.focus();
        }
    }

    onSelectedRef = (el) => {
        if (el) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }

    onKeyDown = (e: *) => {
        switch (e.key) {
        case "ArrowLeft":
            this.navigateToParent();
            break;
        case "ArrowUp":
            if (this.state.focus >= 0) {
                this.setState({ focus: this.state.focus - 1 });
            }

            e.preventDefault();
            break;
        case "ArrowDown":
            if (this.state.focus < Object.keys(this.props.node.children).length) {
                this.setState({ focus: this.state.focus + 1 });
            }

            e.preventDefault();
            break;
        case "ArrowRight":
            const childNames = Object.keys(this.props.node.children).sort();
            this.navigateToChild(childNames[this.state.focus]);
            break;
        default:
        }
    }

    renderChild(i, k, node) {
        return <tr
                key={k}
                onClick={this.navigateToChild.bind(this, k)}
                className={this.state.focus === i ? "kfr-dir-row-sel" : ""}
                ref={this.state.focus === i ? this.onSelectedRef : null}>
            <td>{node.info && node.info.display_name ? node.info.display_name : k}</td>
            <td>{node.info && node.info.description}</td>
            <td>{node.files.length}</td>
            <td>{Object.keys(node.children).length}</td>
        </tr>;
    }

    render() {
        const childNames = Object.keys(this.props.node.children).sort();

        return <div className="kfr-dir" onKeyDown={this.onKeyDown} ref={this.onRef} tabIndex={0}>
            <ul className="kfr-dir-breadcrumbs">
                <li><BreadCrumbLink path={this.props.path} onNavigate={this.props.onNavigate} i={-1} /></li>
                {this.props.path.map((v, i) =>
                        <li key={i}><BreadCrumbLink path={this.props.path} onNavigate={this.props.onNavigate} i={i} v={v}/></li>)}
            </ul>
            <table className="kfr-dir-listing">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Files</th>
                        <th>Children</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.path.length !== 0
                            && <tr onClick={this.navigateToParent.bind(this)}
                                    className={this.state.focus === -1 ? "kfr-dir-row-sel" : ""}
                                    ref={this.state.focus === -1 ? this.onSelectedRef : null}>
                                    <td>..</td>
                                    <td />
                                    <td />
                                    <td />
                            </tr>}
                    {childNames.map((k, i) => this.renderChild(i, k, this.props.node.children[k]))}
                </tbody>
            </table>
        </div>;
    }
}

class ImageGallery extends React.PureComponent {
    urlOfIndex(curNode, idx) {
        return this.props.baseURL + this.props.path.join("/") + "/" + curNode.files[idx];
    }

    onKeyDown = (e: *) => {
        switch (e.key) {
        case "Escape":
        case "ArrowUp":
            const newPath = this.props.path.slice();
            newPath.pop();
            this.props.onNavigate(newPath);
            break;
        case "ArrowLeft":
            if (this.props.index > 0) {
                this.props.onScrollTo(this.props.index - 1);
            }
            break;
        case "ArrowRight":
            if (this.props.index + 1 < this.props.node.files.length) {
                this.props.onScrollTo(this.props.index + 1);
            }
            break;
        default:
        }
    }

    onRef = (el) => {
        if (el) {
            el.focus();
        }
    }

    onClickThumb = (idx, ev) => {
        ev.preventDefault();

        this.props.onScrollTo(idx);
    }

    render() {
        const curNode = this.props.node;

        const MAX_THUMBS = 20;
        const prefix = [];
        const cur = Math.min(this.props.index, curNode.files.length);
        const suffix = [];

        for (let i = 1; 0 <= cur - i && i < MAX_THUMBS; i++) {
            prefix.push(
                    <a
                            key={cur - i}
                            href={buildGalleryLink(cur - i, this.props.path)}
                            onClick={this.onClickThumb.bind(this, cur - i)}>
                        <img src={this.urlOfIndex(curNode, cur - i)} alt="" />
                    </a>);
        }
        for (let i = 1; cur + i < curNode.files.length && i < MAX_THUMBS; i++) {
            suffix.push(
                <a
                        key={cur + i}
                        href={buildGalleryLink(cur + i, this.props.path)}
                        onClick={this.onClickThumb.bind(this, cur + i)}>
                    <img src={this.urlOfIndex(curNode, cur + i)} alt="" />
                </a>);
        }

        return <div tabIndex={0} onKeyDown={this.onKeyDown} ref={this.onRef}>
            <div className="kfr-thumb-bar kfr-thumb-bar-top">
                <div>{prefix}</div>
            </div>
            <div  className="kfr-fullpic">
                <a href={this.urlOfIndex(curNode, cur)}>
                    <img src={this.urlOfIndex(curNode, cur)} alt="" />
                </a>
            </div>
            <div className="kfr-thumb-bar kfr-thumb-bar-bottom">
                <div>{suffix}</div>
            </div>
        </div>;
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);

        const parsed = parseURL(document.location);
        console.log("Recover from URL:", parsed);

        parsed.root = null;
        parsed.baseURL = null;
        parsed.error = false;

        this.state = parsed;
    }

    onPopState = (event) => {
        const parsed = parseURL(document.location);
        console.log("From URL change:", parsed);
        this.setState(parsed);
    };

    componentDidMount() {
        getJSON(development ? "//localhost:8821/index.json" : "/index.json", (res) => {
            if (res) {
                this.setState({
                    error: false,
                    root: res.root,
                    baseURL: res.base,
                });
            } else {
                this.setState({
                    error: true
                });
            }
        });
        window.addEventListener("popstate", this.onPopState);
    }
    componentWillUnmount() {
        window.removeEventListener("popstate", this.onPopState);
    }

    onNavigate = (newPath) => {
        let curNode = this.state.root;
        for (const component of newPath) {
            if (!curNode) {
                break;
            }
            curNode = curNode.children[component];
        }

        const mode = curNode.files.length === 0 ? 'browse' : 'gallery';
        const newURL = mode === 'browse'
                ? buildBrowseLink(newPath)
                : buildGalleryLink(0, newPath);
        window.history.pushState(null, "", newURL);
        this.setState({ "mode": mode, "path": newPath, "index": 0 });
    }

    onScrollTo = (idx) => {
        window.history.replaceState(null, "", buildGalleryLink(idx, this.state.path))
        this.setState({ "index": idx });
    }

    render() {
        let curNode = this.state.root;
        for (const component of this.state.path) {
            if (!curNode) {
                break;
            }
            curNode = curNode.children[component];
        }

        if (curNode) {
            return <div>
                <CopyrightBanner />

                {this.state.mode === 'browse'
                        ? <DirectorySelector
                                path={this.state.path}
                                node={curNode}
                                onNavigate={this.onNavigate} />
                        : <ImageGallery
                                baseURL={this.state.baseURL}
                                path={this.state.path}
                                node={curNode}
                                onNavigate={this.onNavigate}
                                onScrollTo={this.onScrollTo}
                                index={this.state.index} />}
            </div>;
        } else if (this.state.root) {
            // Redirect to valid parent
            const validPath = [];
            let curNode = this.state.root;
            for (const component of this.state.path) {
                curNode = curNode.children[component];
                if (curNode) {
                    validPath.push(component);
                } else {
                    break;
                }
            }

            if (validPath.length !== this.state.path.length) {
                this.onNavigate(validPath);
            }
        }

        return <div className="App">
            <header className="App-header">
                {this.state.error
                        ? <img src={errorIcon} className="kfr-logo-err" alt="error" />
                        : <img src={logo} className="App-logo" alt="logo" />}
                <p>
                    {this.state.error
                            ? "Failed to load!"
                            : "Loading ..."}
                </p>
                {this.state.error
                    && <p><button onClick={() => document.location = "/"}>Try again</button></p>}
            </header>
        </div>;
    }
}

export default App;
